import React, { useState, useEffect } from 'react';
import { StripeProvider } from 'react-stripe-elements';
import {
  BrowserRouter as Router,
  Route,
  Switch
} from 'react-router-dom';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { getProductsFromCollection } from "./util";

import Landing from './components/Landing';
import AboutPage from './components/AboutPage';
import ScrollToTop from './components/ui/ScrollToTop';
import Banner from './components/ui/Banner';
import Collection from './components/product/Collection';
import CollectionList from './components/product/CollectionList';
import Product from './components/product/Product';

import Cart from './components/cart/Cart';
import Checkout from './components/checkout/Checkout';
import Confirm from './components/checkout/Confirm';

import Config from "./routes/Config";

import InitialConfig from "./components/config/InitialConfig";
import Orders from './components/admin/Orders';
import Login from './components/admin/Login';
import MasonryLanding from './components/MasonryLanding/MasonryLanding';
import NotFound404 from "./components/NotFound404"

import { useSelector, useDispatch } from "react-redux";

function App(props) {
  /*
  const [quantity, setQuantity] = useState();

  const config = useSelector(state => state.reducers.config);
  const isAdmin = useSelector(state => state.reducers.isAdmin);
  const dispatch = useDispatch();
 
  useEffect(() => {
    
    document.title = config.store_name;

    const slug = `${config.store_slug}_products`;
    const items = JSON.parse(localStorage.getItem(slug));
    setQuantity(items ? items.length : 0);

    fetch('/user/')
      .then(res => res.json())
      .then(result => {
        dispatch({ type: "SET_ADMIN", isAdmin: result.isAdmin })
      })
  }, []);

  if (!config || typeof isAdmin === "undefined") return (null);
  if (!config.api_key)
    return (
      <Router>
        <InitialConfig />
      </Router>
    );

*/
    const theme = createMuiTheme({
      palette: {
        primary: {
          main: "#ffb6c1", //config.colors.primary.main,
          dark: "black",  //config.colors.primary.dark,
          contrastText: "white" //config.colors.primary.contrastText
        },
        secondary: {
          main:"rgb(255,192,203)",// config.colors.secondary.main,
          contrastText:"black"// config.colors.secondary.contrastText
        },
      },
      typography: {
        fontFamily: [
          'Raleway',
          'Roboto',
          'Helvetica',
          'sans-serif'
        ]
      },
    });

    return (
      <div style={{backgroundColor:'#ffe4e1'}}>
        <Router>
          <ScrollToTop>
            <MuiThemeProvider theme={theme}>
             
              <Switch>
              <MasonryLanding>

              </MasonryLanding>
                <Route component={NotFound404} />
              </Switch>
            </MuiThemeProvider>
          </ScrollToTop>
        </Router>
    </div>
    );
  };
  export default App;