import './MasonryLanding.css'
import React from 'react';
import styled from 'styled-components';
import Paper from '@material-ui/core/Paper';

import Divider from '@material-ui/core/Divider';
import Gallery from 'react-photo-gallery'

//import { XMasonry, XBlock } from "react-xmasonry"; // Imports precompiled bundle



const breakpointColumnsObj = {
  default: 4,
  1100: 3,
  700: 2,
  500: 1
};


//const images = items.map((i, k) => <img key={k} src={require(`../../assets/images/landing/${i}.jpg`)} />)

import Test1 from '../../assets/images/landing/test1.jpg'
import Test2 from '../../assets/images/landing/test2.jpg'
import Test3 from '../../assets/images/landing/test3.jpg'
import Test4 from '../../assets/images/landing/test4.jpg'
import Test6 from '../../assets/images/landing/test6.jpg'
import Test7 from '../../assets/images/landing/test7.jpg'
import Test8 from '../../assets/images/landing/test8.jpg'
import Test9 from '../../assets/images/landing/test9.jpg'
import Test10 from '../../assets/images/landing/test10.jpg'
import Test11 from '../../assets/images/landing/test11.jpg'
import Test12 from '../../assets/images/landing/test12.jpg'
import Test13 from '../../assets/images/landing/test13.jpg'
import Test14 from '../../assets/images/landing/test14.jpg'
import Test15 from '../../assets/images/landing/test15.jpg'
import Test16 from '../../assets/images/landing/test16.jpg'
import Test17 from '../../assets/images/landing/test17.jpg'
import Test18 from '../../assets/images/landing/test18.jpg'
import { ChildCare } from '@material-ui/icons';


const items = [Test1, Test2, Test3, Test4, Test6, Test7, Test8, Test9, Test10, Test11, Test12, Test13, Test14, Test15, Test16, Test17, Test18]

function getRandomInt(max) {
  return Math.floor(Math.random() * max);
}

const alts = [
  "fashionable",
  "modish",
  "voguish",
  "modern",
  "ultra-modern",
  "contemporary",
  "trendsetting",
  "smart",
  "sophisticated",
  "classy",
  "chic",
  "beautiful",
  "modern",
  "smart",
  "sleek",
  "dressy",
  "casual",
  "stylish"
]

const photos = items.map((i, k) => ({ alt: alts[k] + ' fashion', src: i, width: 1, height: 1 }))



//console.log(images.map((i) => <img key={i.key} src={i.props.src} />))
const MasonryLanding = ({ config }) => {
  //  const featuredProducts = getProductsFromCollection(config, "featured-products");

  return (
    <div>
      <div style={{ textAlign: "center" }}><a href='https://www.instagram.com/loveplanet.shop/'>LOVEPLANET.SHOP</a></div>
      <div style={{ textAlign: "center", fontSize: "small" }}>online boutique for women</div>
      <Gallery alt={alts} photos={photos} />
      <div style={{ textAlign: "center", fontSize: "small" }}>
        <ul style={{listStyle: "none"}}>
          <li>
            ИП Кудрявцева Елизавета Владимировна</li>
          <li> ИНН организации: 434581122909</li>
          <li>  ОГРНИП: 321435000034894 </li>

          <li>  shop@loveplanet.shop</li>

          <li> +7 962 988-85-29 </li>
        </ul>
        <br/>
      </div>

    </div>
  );
};

export default MasonryLanding;